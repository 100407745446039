export default [
  {
    id: '@requisition-manage',
    path: '/requisition-manage',
    ref: '/',
    onload: () => import('./requisition-manage-view')
  },
  {
    path: '/requisition-manage-privilege/:id',
    ref: '/',
    onload: () => import('./requisition-manage-view')
  }
]
